<!-- @Author: Yu_Bo -->
<template>
	<div class='role_purview'>
		<!-- 标题 -->
		<div class="vue_title_txt">
		  <span class="title">角色管理</span>
		  <span class="line">/</span>
		  <span class="tip">{{title}}</span>
		</div>
    <div class="purview_top">
      <div class="top">
        <el-input v-model="name" size="small" placeholder="请输入角色名称"></el-input>
      </div>
      <div class="bot">
        <div class="left"><span>*</span> 配置权限</div>
        <div class="right">
          <el-checkbox v-model="allChecked" :true-label='1' :false-label='0' @change='allChangeBtn(list,$event)'>选择全部</el-checkbox>
          <el-button class="btnBgColor_blue" size="small" type="primary" :loading='loading' @click="preserveBtn">保存</el-button>
        </div>
      </div>
    </div>
    <!--  -->
    <div style="padding: 0 20px 20px;">
      <div class="info_data">
        <div class="data_title">
          <span class="span_one">一级</span>
          <span class="span_two">二级</span>
          <span>三级</span>
        </div>
        <div class="data_list" v-for="(item,index) in list" :key="index">
          <div class="div_one">
            <el-checkbox v-model="item.is_select" :true-label='1' :false-label='0' @change="oneChangeBtn(index,$event)">{{item.name}}</el-checkbox>
          </div>
          <div class="div_two">
            <div class="two_list" v-if="!item.children || item.children.length==0">
              <div class="left"></div>
              <div class="right"></div>
            </div>
            <div class="two_list" v-for="(ite,idx) in item.children" :key="idx">
              <div class="left">
                <el-checkbox v-model="ite.is_select" :true-label='1' :false-label='0' @change="twoChangeBtn(index,idx,$event)">{{ite.name}}</el-checkbox>
              </div>
              <div class="right">
                <div class="right_text" v-for="(ie,ix) in ite.children" :key="ix">
                  <el-checkbox v-model="ie.is_select" :true-label='1' :false-label='0' @change="threeChangeBtn(index,idx,ix,$event)">{{ie.name}}</el-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{
				title:'新增',
        id:'',
        name:'',
        loading:false,
        //
        allChecked:0,
        // 权限树型结构
        list:[],
        // 已分配的权限
        roleList:[],
			}
		},
		computed: {},
		watch: {
      'list':{
          deep:true,
          handler() {
            this.roleList=[]
            this.roleListInfo(this.list)
            const arr = this.allcheckbox(this.list,[1])
            if(arr){
              this.allChecked=0
            }else{
              this.allChecked=1
            }
          }
        }
    },
		created() {
      if(this.$route.query.id){
        this.title='编辑'
        this.id=this.$route.query.id
        this.name=this.$route.query.name
        this.editGetList()
      }else{
        this.title='新增'
        this.id=''
        this.name=''
        this.addGetList()
      }
    },
		mounted() {},
		methods: {
      // 获取编辑树型结构
      editGetList(){
        var that = this
        that.$purviewApi.getSystemRole('',that.id).then(res => {
          if (res.code == 1000) {
            that.list=res.result.menu
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 获取添加树型结构
      addGetList(){
        var that = this
        that.$purviewApi.roleMenuList().then(res => {
          if (res.code == 1000) {
            that.list=res.result
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      //
      allcheckbox(arr,show){
        for(var a=0;a<arr.length;a++){
          if(arr[a].is_select==0){
            let shows = [...show,arr[a].is_select]
            return shows.length==1?this.allcheckbox(arr[a].children,shows):shows
          }else if(arr[a].children && arr[a].children.length){
            let falg = this.allcheckbox(arr[a].children,[...show,arr[a].is_select])
            if(falg) return falg
          }
        }
      },
      roleListInfo(arr){
        for(var i=0;i<arr.length;i++){
          if(arr[i].is_select==1){
            this.roleList.push(arr[i].id)
          }
          if(arr[i].children && arr[i].children.length){
            this.roleListInfo(arr[i].children)
          }
        }
      },
      // 全选点击
      allChangeBtn(list,val){
        this.recursiveBtn(this.list,val)
      },
      // 保存
      preserveBtn(){
        var that = this
        if(that.name==''){
          this.$errMsg('请输入角色名称')
          return
        }else if(this.roleList.length==0){
          this.$errMsg('请分配该角色的权限')
          return
        }else if(that.id){
          // 编辑
          that.loading = true
          var params = {
            name: that.name,
            menu_ids: that.roleList.join(','),
          }
          that.$purviewApi.putSystemRole(params,that.id).then(res => {
            that.loading = false
            if (res.code == 1000) {
              that.backBtn()
              that.$succMsg(res.message)
            } else {
              that.$errMsg(res.message)
            }
          })
        }else{
          // 添加
          that.loading = true
          var params = {
            name: that.name,
            menu_ids: that.roleList.join(','),
          }
          that.$purviewApi.postSystemRole(params).then(res => {
            that.loading = false
            if (res.code == 1000) {
              that.backBtn()
              that.$succMsg(res.message)
            } else {
              that.$errMsg(res.message)
            }
          })
        }
      },
      // 返回
      backBtn(){
        this.$router.back()
      },
      // 递归赋值show
      recursiveBtn(arr,val){
        arr.forEach(item=>{
          item.is_select=val
          if(item.children && item.children.length){
            this.recursiveBtn(item.children,val)
          }
        })
      },
      // 一级选择
      oneChangeBtn(index,val){
        if(this.list[index].children && this.list[index].children.length){
          this.recursiveBtn(this.list[index].children,val)
        }
      },
      // 二级选择
      twoChangeBtn(index,idx,val){
        var arr = this.list[index].children
        var obj = arr.filter(item=>item.is_select==1)
        if(obj.length){
          this.list[index].is_select=1
        }else{
          this.list[index].is_select=0
        }
        if(this.list[index].children[idx].children && this.list[index].children[idx].children.length){
          this.recursiveBtn(this.list[index].children[idx].children,val)
        }
      },
      // 三级
      threeChangeBtn(index,idx,ix,val){
        if(val){
          this.list[index].is_select=val
          this.list[index].children[idx].is_select=val
        }else{
          var arr = this.list[index].children[idx].children
          var obj = arr.filter(item=>item.is_select==1)
          if(obj.length){
            this.list[index].is_select=1
            this.list[index].children[idx].is_select=1
          }else{
            this.list[index].children[idx].is_select=0
            this.twoChangeBtn(index,idx,0)
          }
        }
      },
    },
	}
</script>

<style lang='scss' scoped>
	.role_purview{
		width: 100%;
    .purview_top{
      width: 100%;
      padding: 20px;
      .top{
        width: 100%;
        display: flex;
        .el-input{
          width: 160px;
        }
      }
      .bot{
        width: 100%;
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333;
          span{
            color: #DA1616;
          }
        }
        .right{
          display: flex;
          align-items: center;
          .el-button{
            margin-left: 30px;
          }
        }
      }
    }
    .info_data{
      width: 100%;
      border: 1px solid #E9E9E9;
      border-radius: 2px;
      .data_title{
        width: 100%;
        height: 50px;
        background: #F0F2F5;
        display: flex;
        span{
          display: block;
          padding: 0 20px;
          line-height: 50px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          border-right: 1px solid #EDEDED;
        }
        span:last-child{
          border-right: none;
        }
        .span_one{
          width: 150px;
        }
        .span_two{
          width: 200px;
        }
      }
      .data_list{
        width: 100%;
        display: flex;
        .div_one{
          width: 150px;
          padding: 0 20px;
          border-right: 1px solid #EDEDED;
          border-bottom: 1px solid #EDEDED;
          display: flex;
          align-items: center;
        }
        .div_two{
          width: calc(100% - 150px);
          .two_list{
            display: flex;
            .left{
              width: 200px;
              padding: 0 20px;
              border-right: 1px solid #EDEDED;
              border-bottom: 1px solid #EDEDED;
              display: flex;
              align-items: center;
            }
            .right{
              width: calc(100% - 200px);
              min-height: 60px;
              padding: 5px 20px;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              border-bottom: 1px solid #EDEDED;
              .right_text{
                padding: 5px 20px 5px 0;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
      .data_list:last-child{
        .div_one{
          border-bottom: none;
        }
        .div_two:last-child{
          .two_list:last-child{
            .left{
              border-bottom: none;
            }
            .right{
              border-bottom: none;
            }
          }
        }
      }
    }
	}
</style>
